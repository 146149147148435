@import 'variables';

.breadcrumbs {
	display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
  gap: .5rem;
}
