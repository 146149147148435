@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/breadcrumbs';

.converter {
	text-align: center;

	&__title {
		font-size: $font-size3;
    font-weight: 600;
    margin-bottom: 1rem;
		margin: 2rem 0;

		&_major {
			font-size: $font-size5;
			font-weight: 600;
		}
	}

	&__top-list {
		li {
	    display: inline-block;
	    margin: 0 1rem 1rem 0;
		}
	}

	&__table {
		max-width: fit-content;
	}

	&__bottom {
		&-wrap {
			display: inline-block;
		}
	}
}

.flex-row-container > .flex-row-item {
  flex: 0 1 calc((100% / 3) - 2rem);
}

.widget__input {
  display: inline-flex !important;
  gap: 1rem;
    flex-direction: row;
    max-width: 100%;

  .widget__input-group {
    width: 288px;
  }
}

.widget__input > span {
  height: 40px;
  padding-top: 13px
}

.widget__input .widget__input-number {
  width: 100%;
}

.widget__select-unit {
  width: 100%;
  padding: .5rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: $color6;
  border: none;
}

.widget__select-unit::-webkit-scrollbar-thumb {
  background-color: #3c3f41;
  border: 4px solid transparent;
  background-clip: padding-box;
}

.widget__select-unit::-webkit-scrollbar {
  width: 16px;
}

#infoContainerButton {
	display: none;
}

.info-container__item:first-child {
	padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}